import axios from 'axios'
const config = require('../../config');
import { notify } from "@kyvg/vue3-notification";

const state = {
  user: null,
  token: null
}

const getters = {
  getUser: state => state.user,
  getToken: state => state.token,
  isLoggedIn: state => state.user !== null
}

const mutations = {
  setUser: (state, user) => {
    state.user = user
  },
  setToken: (state, token) => {
    localStorage.setItem('token', token);
    state.token = token;
  }
}

const actions = {
  async getProfile ({ commit }) {
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/client/get/profile&token=${this.getters.getToken}`);
      // console.log(resp);
      if (resp.data.result == 1){
        commit('setUser', resp.data.data);
      } else {
        commit('setUser', null);
      }
    } else {
      commit('setUser', null);
    }
  },
  async loginUser ({commit}, obj) {
    try {
      let query = `${config.baseUrl}?address=/scan/login&username=${obj.username}&password=${obj.password}`;
      if (this.getters.getToken != null) {
        query += `&token=${this.getters.getToken}`;
      }
      const resp = await axios.post(query);
      if (resp.data.result == 1){
        let newToken = resp.data.token;
        if (newToken != null) {
          commit('setToken', newToken);
        }
        this.dispatch("getProfile");
      }
      return resp.data;
    } catch(e) {
      notify({
        title: "Eroare de conexiune cu serverul.",
        type: "error",
      });
      return Promise.reject(e);
    }
    
  },
  async logout({commit}) {
    if (this.getters.getToken != null) {
      const resp = await axios.post(`${config.baseUrl}?address=/scan/logout&token=${this.getters.getToken}`);
      if (resp.data.result == 1){
        commit('setUser', null);
      }
      return resp.data;
    }
  },
}

export function getTokenParam(token) {
  if (token == null){
    return "";
  } else {
    return `&token=${token}`
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}