import axios from 'axios'
const config = require('../../config');
const state = {}
const getters = {}
const mutations = {}
const actions = {
  async checkScan (_, obj) {
    let query = `${config.baseUrl}?address=/client/get/info`;
    if (obj.trId != null) {
      query += `&trId=${obj.trId}`;
    } 
    if (obj.barcode != null) {
      query += `&barcode=${obj.barcode}`
    }
    if (obj.eiId != null) {
      query += `&eiId=${obj.eiId}`
    }
    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    return resp.data;
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}