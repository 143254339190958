<template>

  <div class="font-sans antialiased">
    <nav v-if="isLoggedIn" class="flex items-center justify-between flex-wrap bg-gray-800 p-6">
      <div class="block sm:hidden" style="margin-left: auto;">
        <button @click="toggle" class="flex items-center px-3 py-2 border rounded text-white border-white">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Meniu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div :class="open ? 'block': 'hidden'" class="w-full flex-grow sm:flex sm:items-center sm:w-auto">
        <div class="sm:flex-grow">
              <router-link to="/events" class="text-white px-3 py-2 no-underline block sm:inline-block sm:mt-0 text-white hover:text-gray-50 mr-4">
                Evenimente
              </router-link>
              <router-link to="/check-scan" class="text-white px-3 py-2 no-underline block sm:inline-block sm:mt-0 text-white hover:text-gray-50 mr-4">
                Verificare rapida
              </router-link>
              <router-link to="/add-event" class="text-white px-3 py-2 no-underline block sm:inline-block sm:mt-0 text-white hover:text-gray-50 mr-4">
                Adauga eveniment
              </router-link>
        </div>
        <div>
          <button class="text-white px-3 py-2" @click="logout">Logout</button>
        </div>
      </div>
    </nav>
      <router-view></router-view>
      <notifications position="top center" />
    </div>
  </template>
  
  <script>
  import { useStore, mapActions } from 'vuex'
  export default {
    data() {
      return {
        open: false
        // ...
      };
    },
    name: 'App',
    methods: {
      ...mapActions(['logout']),
      async logout() {
          const resp = await this.$store.dispatch("logout");
          if (resp.result == 1) {
            this.$router.push("/");
          }
      },
      toggle() {
        this.open = !this.open;
      },
    },
    setup: () => {
      //TODO - rememberToken
      const store = useStore();
      console.log(localStorage.getItem('token'))
      if (localStorage.getItem('token') != null) {
        store.commit('setToken', localStorage.getItem('token'));
      }
      
    },
    computed: {
      isLoggedIn() {
        return this.$store.getters.isLoggedIn;
      },
    }
  }
  </script>
  
  <style>
  body {
    font-family: Arial, sans-serif;
  }
  
  .alert {
    height: 48px;
    background-color: #FFC8C8;
    color: #E20000;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 40px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .border-red {
    border-color: #E20000!important;
  }
  
  label.border-red {
    border-bottom: 1px solid #E20000;
  }
  
  .alert::before {
    content: '!';
    position: absolute;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    left: 10px;
    top:13px;
    background-color: #FF0000;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #FFC8C8;
    align-items: center;
    justify-content: center;
  }
  
  .topnav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  </style>
  