import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'tailwindcss/tailwind.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'vue-loading-overlay/dist/css/index.css';
import './index.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.js';
import Notifications from '@kyvg/vue3-notification'

createApp(App).use(router).use(store).use(Notifications).mount('#app');
