import Vuex from 'vuex'
import user from './modules/user'
import tickets from './modules/tickets'
import events from './modules/events'

export default new Vuex.Store({
  modules: {
    user,
    tickets,
    events
  }
})