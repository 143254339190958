import axios from 'axios'
const config = require('../../config');

const state = {}
const getters = {}
const mutations = {}
const actions = {

  async getEvents (_, obj) {

    let query = `${config.baseUrl}?address=/client/get/events`;
    if (obj.startDate != null) {
      query += `&startDate=${obj.startDate}`;
    } 
    if (obj.endDate != null) {
      query += `&endDate=${obj.endDate}`
    }

    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    return resp.data;
  },

  async addEvent (_, obj) {

    let query = `${config.baseUrl}?address=/client/add/event`;
    if (obj.startDate != null) {
      query += `&name=${obj.eventName}`;
    }
    if (obj.startDate != null) {
      query += `&dateStart=${obj.startDate}`;
    }
    if (obj.scanDateStart != null) {
      query += `&scanDateStart=${obj.scanDateStart}`;
    }
    if (obj.scanDateEnd != null) {
      query += `&scanDateEnd=${obj.scanDateEnd}`
    }
    if (obj.clientId != null) {
      query += `&clientId=${obj.clientId}`
    }
    if (obj.id != null) {
      query += `&id=${obj.id}`
    }
    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    return resp.data;
  },
  async addBarcodes (_, obj) {
    let query = `${config.baseUrl}?address=/client/add/barcodes`;
    if (obj.eiid != null) {
      query += `&eiid=${obj.eiid}`;
    }
    if (obj.barcodes != null) {
      query += `&barcodes=${obj.barcodes}`;
    }
    if (obj.noScans != null) {
      query += `&noScans=${obj.noScans}`;
    }
    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    return resp.data;
  },
  async resetEvent (_, obj) {

    let query = `${config.baseUrl}?address=/client/reset/event`;
    if (obj.eiid != null) {
      query += `&eiid=${obj.eiid}`;
    }
    if (obj.clearHistory != null) {
      query += `&clearHistory=${obj.clearHistory}`;
    }
    if (obj.noScans != null) {
      query += `&noScans=${obj.noScans}`;
    }
    if (obj.password != null) {
      query += `&password=${obj.password}`
    }
    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    console.log(resp.data);
    return resp.data;
  },
  async getEvent (_, obj) {

    let query = `${config.baseUrl}?address=/client/get/event`;
    if (obj.id != null) {
      query += `&id=${obj.id}`;
    } 
    if (this.getters.getToken != null) {
      query += `&token=${this.getters.getToken}`;
    }
    const resp = await axios.post(query);
    return resp.data;
  },

}

export default {
  state,
  getters,
  mutations,
  actions,
}